<template>
<v-container>
    <v-row class="p-relative zindex-1 full-width ma-0">
        <v-col cols="12" align="center" justify="center">
            <h3 class="heading--text">Profile</h3>
        </v-col>
    </v-row>

    <v-row justify="center" class="p-relative zindex-1">
        <v-col cols="12" md="4" lg="3" v-for="(item, index) in sections" :key="index" :class="{'my-5':$vuetify.breakpoint.mdAndUp}">
            <template v-if="$vuetify.breakpoint.smAndDown">
                <meo-list-item section :item="item" @click.native="goTo(item)"></meo-list-item>
            </template>

            <template v-else>
                <meo-card-item section :item="item" @click.native="goTo(item)"></meo-card-item>
            </template>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// Components
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";

export default {
    name: 'profile-menu',
    meta: {
        titleTemplate: "Profile | %s"
    },

    props: {
        sections: {
            type: Array
        }
    },

    components: {
        MeoListItem,
        MeoCardItem
    },

    methods: {
        goTo(item) {
            if (item.name == "logout") {

                this.$fluro.auth.logout();
            } else {
                if (item.id) {
                    this.$router.push({ name: item.name, params: { id: item.id } });
                } else {
                    this.$router.push({ name: item.name });
                }
            }
        },
    }
}
</script>

<style lang="scss">

</style>
