<template>
<v-card class="meo-list-item" :dark="item.dark" :ripple="{ class: 'primary--text' }">
    <div class="item-image mr-4">
        <template v-if="(item.data && item.data.image) || item.image">
            <div class="image" :style="backgroundImage(item)"></div>
        </template>

        <template v-if="(item.data && item.data.icon) || item.icon">
            <template v-if="(item.data && !item.data.image) || !item.image">
                <div class="image">
                    <i class="dark--text" :class="(item.data && item.data.icon) || item.icon"></i>
                </div>
            </template>

            <template v-else>
                <div class="pa-2 img">
                    <v-img :src="item.image" />
                </div>
            </template>
        </template>

        <template v-if="item._type == 'audio'">
            <div class="image">
                <i class="dark--text neu-tea-leaf"></i>
            </div>
        </template>
    </div>

    <div class="text mr-2 d-flex flex-column" :class="{ 'muted-1': !purchased && !section }">
        <div class="line-clamp">
            <b v-html="title"></b>
        </div>

        <div class="line-clamp">
            <span class="caption muted-1" v-if="(item.data && item.data.description) || item.description" v-html="item.data && item.data.description ? item.data.description : item.description"></span>
        </div>
    </div>

    <template v-if="favourite">
        <div class="icon ml-auto">
            <!-- <v-icon :color="item.dark ? 'light' : 'dark'">mdi-heart</v-icon> -->
            <meo-favourite-button :section="item" :oldContact="contact"></meo-favourite-button>
        </div>
    </template>

    <template v-else-if="!purchased && !section && !$meo.loading">
        <div class="icon ml-auto muted-1">
            <v-icon :color="item.dark ? 'light' : 'dark'">mdi-lock-outline</v-icon>
        </div>
    </template>

    <template v-else-if="completed">
        <div class="icon ml-auto">
            <v-icon color="success">mdi-check-circle-outline</v-icon>
        </div>
    </template>

    <template v-else>
        <div class="icon ml-auto">
            <v-icon :color="item.dark ? 'light' : 'dark'">mdi-arrow-right</v-icon>
        </div>
    </template>
</v-card>
</template>

<script>
import MeoFavouriteButton from "./meo-favourite-button.vue";

export default {
    name: "meo-list-item",

    components: {
        MeoFavouriteButton,
    },

    props: {
        item: {
            type: Object,
        },
        purchased: Boolean,
        section: Boolean,
        favourite: Boolean,
        completed: Boolean,
        contact: {
            type: Object,
        },
    },

    computed: {
        title() {
            let title = this.item.title;
            let display = this.item.data && this.item.data.displayTitle;
            let section = this.item.data && this.item.data.sectionName;

            if (section) title = `Today's ${section}`;
            if (display) title = display;

            return title;
            // item.data && item.data.displayTitle ? `Today's ${item.data.sectionName}` : item.title
        }
    },

    methods: {
        backgroundImage(item) {
            if ((item.data && !item.data.icon) || !item.icon) {
                if ((item.data && item.data.image) || item.image) {
                    return {
                        backgroundImage: `url(${this.$fluro.asset.imageUrl(item.data.image || item.image)})`,
                    };
                }
            }

            return {};
        },
    },
};
</script>

<style lang="scss">
.meo-list-item {
    background: #ffffff;
    border-radius: 10px !important;
    padding: 10px;
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;

    .item-image {
        width: 47px;
        height: 47px;
        background: var(--v-primary-base);
        border-radius: 10px;
        padding: 5px;
        flex-shrink: 0;
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #ffffff;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }

    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
</style>
