<template>
    <v-btn class="meo-favourite-button" icon text @click.stop="setFavourite(), (favourite = !favourite)">
        <v-icon v-if="!isFavourite" class="favourite-size" color="var(--v-heading-base)">mdi-heart-outline</v-icon>
        <v-icon v-else class="favourite-size" color="var(--v-heading-base)">mdi-heart</v-icon>
    </v-btn>
</template>

<script>
//services
import Contact from "@/modules/meo-module/contact";

export default {
    name: "meo-progress-tracker",
    data() {
        return {
            favourite: false,
            contact: {},
        };
    },

    props: {
        oldContact: {
            type: Object,
        },
        section: {
            type: Object,
        },
    },

    computed: {
        isFavourite() {
            if (this.favourite === false) {
                return false;
            } else {
                return true;
            }
        },
    },

    methods: {
        async init() {
            // console.log("current section in meo-fav", this.section, this.contact);
            // on mount check to see if favourites exists and if it has any entries
            let correctRoute = (route) => {
                if (route === "module.screen") {
                    return true;
                } else if (route === "profile.favourites") {
                    return true;
                } else if (route === "module") {
                    return true;
                } else {
                    return false;
                }
            };

            if (this.oldContact?._id && correctRoute(this.$route.name)) {
                let newContact = await Contact.get(this.oldContact._id).then(({ data }) => data);
                this.contact = newContact;
                console.log("did get request for contact", this.contact);
            }

            if (this.contact?.data?.favourites?.length) {
                console.log("contact had favourites");
                // check to see if that list contains the curront section ID
                let sectionId = this.section?._id || this.section?.screenId;
                if (sectionId && this.contact?.data?.favourites.includes(this.section?._id || this.section?.screenId)) {
                    // if it does set the heart icon to be filled indicating it is already favourited
                    this.favourite = true;
                    console.log("favourite is true");
                }
            } else {
                let localFavourites = JSON.parse(window.localStorage.getItem("favourites"));
                if (localFavourites?.includes(this.section._id || this.section?.screenId)) {
                    this.favourite = true;
                }
            }
        },
        async setFavourite() {
            // check to see if there is a user in the session (someone is logged in or not)

            if (this.contact?.data) {
                // check to see if favourites array exists and if there is anything in it
                if (this.contact?.data?.favourites?.length) {
                    // check to see if the contacts favourite array includes the current section ID
                    if (this.contact.data.favourites.includes(this.section._id || this.section?.screenId)) {
                        // if it does find the index of the current section ID in the favourites array and remove the entry at that index
                        let id = this.section._id || this.section.screenId;
                        let index = this.contact.data.favourites.findIndex((item) => item === id);

                        this.contact.data.favourites.splice(index, 1);
                    } else {
                        // if the favourites array doesnt contain the current ID then push it into that array
                        this.contact.data.favourites.push(this.section?._id || this.section?.screenId);
                    }
                } else {
                    // if favourites array doesnt exist or there is nothing in it
                    // create it as an array containing the current section ID

                    this.contact.data = { favourites: [this.section?._id || this.section?.screenId] };
                }

                await Contact.update(this.contact._id, this.contact);
                console.log(this.contact, "contact has been updated");
            } else {
                // if no user logged in save data to local storage
                // check local storage for existing favourites
                let localFavourites = JSON.parse(window.localStorage.getItem("favourites"));

                if (localFavourites) {
                    // if local Favourites exists then check to see if current id already favourited
                    let match = localFavourites.includes(this.section._id);

                    if (match) {
                        // if so filter out this sections id from the list and update to unfavourite
                        let updatedFavourites = localFavourites.filter((item) => item != this.section._id);

                        window.localStorage.setItem("favourites", JSON.stringify(updatedFavourites));
                    } else {
                        // if current section Id not in favourites set local Favourites to everything currently in there + this section ID
                        window.localStorage.setItem(
                            "favourites",
                            JSON.stringify([...localFavourites, this.section._id])
                        );
                    }
                } else {
                    // if localFavourites doesn't exist, create LocalFavourites array with current section id
                    window.localStorage.setItem("favourites", JSON.stringify([this.section._id]));
                }
            }

            // regardless of if any checks were passed update the contact in the backend with this components copy of contact it edited
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss">
.meo-favourite-button {
    cursor: pointer;
}
</style>
